import { dummyClient, refineClientFromApi } from "@app/actions/client-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { clientTableStore } from "../ClientsPage/clientTableStore";
import { observable } from "mobx";

export class ClientFormStore extends FormDrawerStore<IClient> {
    public autofocus = "first_name";
    public dataName = "client";
    public dummyDataFn = dummyClient;
    public apiUrl = endpoints.client;
    @observable public _data = dummyClient();
    public afterSaveCallback?: (d: IClient) => void;

    get tableStore(): DataTableStore {
        return clientTableStore;
    }

    set tableStore(s: DataTableStore){
        
    }

    public afterSave = (d: IClient) => {
        d = refineClientFromApi(d);
        clientTableStore.dataProvider.updateLocalCopyOfData(d);
        if (this.afterSaveCallback) {
            this.afterSaveCallback(d);
            this.afterSaveCallback = undefined;
        }
    }
}

export const clientFormStore = new ClientFormStore;

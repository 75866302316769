import { sendAction } from "@app/actions/terminal-actions";
import { Button, Dropdown, Icon, Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

interface ITerminalActionButtonProps {
    terminal: ITerminal;
}

@observer
export class TerminalActionButton extends React.Component<ITerminalActionButtonProps> {

    @observable public loading = false;

    @observable public dataViewerVisible = false;
    @observable public dataViewerDataType = "receipt";

    public onClick = async ({ key }: ClickParam): Promise<void> => {
        if (key.startsWith("view")) {
            this.dataViewerVisible = true;
            this.dataViewerDataType = key.split("-")[1];
        } else {
            this.loading = true;
            await sendAction(this.props.terminal, key as any);
            this.loading = false;
        }
    }

    public menu = (): JSX.Element => <Menu onClick={this.onClick}>
        <Menu.Item key="refresh"> <Icon type="reload" /> Refresh</Menu.Item>
        <Menu.Item key="switch-stage"> <Icon type="reload" /> Update Stage</Menu.Item>
        <Menu.Item key="clear-cache">
            <img src="/svg/swipe.svg" style={{ width: "1em", height: "1em" }} className="anticon" />
            Clear cache
        </Menu.Item>
        <Menu.Item key="clear-system-cache">
            Clear System Cache
        </Menu.Item>
        <Menu.Item key="clear-system-data">
            Clear System Data
        </Menu.Item>
        <Menu.Item key="vorlon">
            <img src="/svg/vorlon-green.svg" style={{ width: "1em", height: "1em" }} className="anticon" />
            Vorlon
        </Menu.Item>

        {/* <Menu.Divider /> */}
        <Menu.ItemGroup title="Snapshot">
            <Menu.Item key="upload-receipt-data">
                Receipts
            </Menu.Item>
            <Menu.Item key="upload-customer-data">
                Customers
            </Menu.Item>
        </Menu.ItemGroup>
        
    </Menu>

    public render(): JSX.Element {
        return <>
            <Dropdown trigger={["click"]} overlay={this.menu()}>
                <Button loading={this.loading} style={{marginRight: 5}}>
                    Action
            <Icon type="down" />
                </Button>
            </Dropdown>
        </>;
    }
}

import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { eSet, set } from "@app/lib/utils";
import { ClientForm } from "@app/pages/clients/ClientForm/ClientForm";
import { Form, Input, Button, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { terminalFormStore as store, terminalFormStore } from "./TerminalFormStore";
import { partial } from "lodash";
import _ from "lodash";
import { Devices } from "@app/data/devices";
import JSONView from "@app/components/JSONView";

@observer
class TerminalFormBare extends React.Component<FormComponentProps> {

    public componentDidMount(): void {
        store.formUtils = this.props.form;
    }

    public handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                store.refineForApi = (x: any) => {
                    if (x.devices_setting) {
                        x.devices_setting = JSON.stringify(x.devices_setting);
                    }
                    return x;
                };
                store.afterSave = (x: any) => {
                    if (x.devices_setting) {
                        x.devices_setting = JSON.parse(x.devices_setting);
                    }
                };
                store.save();
            }
        });
    }

    public render(): JSX.Element {
        const terminal = store.data;
        const { getFieldDecorator } = this.props.form;

        return <>
            <FormDrawer store={store} >
                <ClientForm />
                <Form layout="horizontal" onSubmit={this.handleSubmit}>
                    <Form.Item label="Name" >
                        {getFieldDecorator("name", {
                            initialValue: terminal.name,
                            rules: [{ required: true }]
                        })(
                            <Input placeholder="Name" onChange={eSet(terminal, "name")} />
                        )}
                    </Form.Item>

                    <Form.Item label="Password" >
                        {getFieldDecorator("password", {
                            rules: [{ min: 5, required: !terminal.id }],
                        })(
                            <Input placeholder="Password" type="password" onChange={eSet(terminal, "password")} />
                        )}
                    </Form.Item>

                    <Form.Item label="Venue">
                        {getFieldDecorator("venue_id", {
                            initialValue: terminal?.venue_id,
                        })(<Select disabled={!_.isNil(terminal?.id)} onChange={partial(set, terminal, "venue_id")}>
                            {_.map(terminalFormStore.venues, venue => <Select.Option value={venue.id} key={venue.id}>
                                {venue.name}
                            </Select.Option>)}
                        </Select>)}
                    </Form.Item>

                    <Form.Item label="Accessible by">
                        {getFieldDecorator("device_type", {
                            initialValue: terminal.device_type,
                        })(<Select onChange={partial(set, terminal, "device_type")}>
                            {_.map(Devices, (device, i) => <Select.Option value={parseInt(i)} key={parseInt(i)}>
                                {device}
                            </Select.Option>)}
                        </Select>)}
                    </Form.Item>

                    <Form.Item label="Remote Control" >
                        {getFieldDecorator("remote_control_id", {
                            initialValue: terminal.devices_setting?.remote_control_id,
                            rules: [{ required: false }]
                        })(
                            <Input placeholder="Remote Control" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                terminal.devices_setting!.remote_control_id = e.currentTarget.value;
                            }} />
                        )}
                    </Form.Item>

                    <JSONView src={toJS(store.data)} name={store.dataName} />

                    <Button type="primary" loading={store.loading}
                        htmlType="submit" style={{ marginRight: 5 }}>
                        Save
                    </Button>
                    <Button type="default" onClick={store.hide} htmlType="button">Cancel</Button>

                </Form>
            </FormDrawer>
        </>;
    }
}

export const TerminalForm = Form.create()(TerminalFormBare);

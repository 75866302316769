import { terminalsUrl } from "@app/actions/brand-actions";
import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import VenueItem from "./misc/VenueItem";

type IVenuesContainer = {
    brand: IBrand,
}

const VenuesContainer = ({ brand }: IVenuesContainer) => {
    const brandId = brand?.id;
    const [venueList, setVenueList] = useState(brandFormStore.venues);
    const [venueName, setVenueName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [deletedVenues, setDeletedVenues] = useState<number[]>([]);

    const handleCreate = async () => {
        setIsLoading(true);
        await brandFormStore.createVenue(brandId, venueName).then(res => {
            setVenueName("");
            message.success(res?.message || "Created. It will take a few minutes to complete the process.");
            setIsLoading(false);
            setTimeout(refreshVenues, 5000);
        }).catch(() => setIsLoading(false))
    }

    const handleDelete = (id: number) => {
        setDeletedVenues(prev => [...prev, id]);
        setTimeout(refreshVenues, 5000);
    }

    const refreshVenues = async () => {
        const venues = await brandFormStore.loadVenues();
        setVenueList(venues);
    }

    return (
        <div>
            <div style={{ display: "flex", gap: 10 }}>
                <Input
                    placeholder={"New venue name"}
                    value={venueName}
                    onChange={e => setVenueName(e.target.value)}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button onClick={handleCreate} loading={isLoading}>Add</Button>
                    <Link to={terminalsUrl(brand.id!)}>Terminals</Link>
                </div>
            </div>
            <div style={{ marginTop: 30 }}>
                {venueList?.map(vL => (
                    <VenueItem
                        isDeleted={deletedVenues.indexOf(vL.id) !== -1}
                        deleteDisabled={venueList.length === 1}
                        brandId={brand?.id}
                        venue={vL}
                        onDelete={handleDelete}
                        refreshVenues={refreshVenues}
                    />
                ))}
            </div>
        </div>
    )
}

export default VenuesContainer;
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { CountriesAll, eSet, set } from "@app/lib/utils";
import { clientFormStore } from "@app/pages/clients/ClientForm/ClientFormStore";
import { Button, Form, Input, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import _ from "lodash";
import { sessionStore } from "@app/stores/SessionStore";
import JSONView from "@app/components/JSONView";
import { isDev } from "@app/config/main";

@observer
class ClientFormBare extends React.Component<FormComponentProps> {
    public componentDidMount(): void {
        clientFormStore.formUtils = this.props.form;
    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                clientFormStore.save();
            }
        });
    };

    public render(): JSX.Element {
        const countries = _.intersectionWith(
            CountriesAll,
            sessionStore?.user?.partner?.countries || [],
            (a, b) => a.value === b
        );

        const client = clientFormStore.data;
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <FormDrawer store={clientFormStore}>
                    <Form layout="vertical" onSubmit={this.handleSubmit}>
                        <Form.Item label="First name">
                            {getFieldDecorator("first_name", {
                                initialValue: client?.first_name,
                                rules: [{ required: true }],
                            })(<Input placeholder="Last name" onChange={eSet(client, "first_name")} />)}
                        </Form.Item>

                        <Form.Item label="Last name">
                            {getFieldDecorator("last_name", {
                                initialValue: client.last_name,
                                rules: [{ required: true }],
                            })(<Input placeholder="Last name" onChange={eSet(client, "last_name")} />)}
                        </Form.Item>

                        <Form.Item label="Email">
                            {getFieldDecorator("email", {
                                initialValue: client.email,
                                rules: [{ required: true }],
                            })(<Input placeholder="Email" onChange={eSet(client, "email")} />)}
                        </Form.Item>

                        <Form.Item label="Password">
                            {getFieldDecorator("password", {
                                rules: [{ min: 5, required: !client.id }],
                            })(<Input placeholder="Password" type="password" onChange={eSet(client, "password")} />)}
                        </Form.Item>

                        <Form.Item label="Phone">
                            {getFieldDecorator("phone", {
                                initialValue: client.phone,
                                rules: [{ required: false }],
                            })(<Input placeholder="Phone" onChange={eSet(client, "phone")} />)}
                        </Form.Item>

                        {/* <Form.Item label="Country">
                        <Select defaultValue={_.get(client, "country")} onChange={set(client, "country")}>
                            {countries.map(country => <Select.Option key={country.name} value={country.value}>
                                {country.name}
                            </Select.Option>)}
                        </Select>
                    </Form.Item> */}

                        {isDev ? <JSONView src={toJS(client)} collapsed={1} name={clientFormStore.dataName} /> : null}

                        <Button
                            type="primary"
                            loading={clientFormStore.loading}
                            htmlType="submit"
                            style={{ marginRight: 5 }}
                        >
                            Save
                        </Button>
                        <Button type="default" onClick={clientFormStore.hide} htmlType="button">
                            Cancel
                        </Button>
                    </Form>
                </FormDrawer>
            </>
        );
    }
}

export const ClientForm = Form.create()(ClientFormBare);

import { brandFormStore } from "@app/pages/brands/BrandForm/BrandFormStore";
import { Button, Input, message, Popconfirm, Switch } from "antd";
import React, { useState } from "react";

interface IVenueItem {
    isDeleted: boolean;
    deleteDisabled: boolean;
    brandId: number | undefined;
    venue: IVenue;
    onDelete: (id: number) => void;
    refreshVenues: () => void;
}

const VenueItem = ({ brandId, venue, isDeleted, deleteDisabled, onDelete, refreshVenues }: IVenueItem) => {
    const [isEditMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpdt, setIsLoadingUpdt] = useState(false);
    const [venueStatus, setVenueStatus] = useState(venue.status);
    const [venueName, setVenueName] = useState(venue.name);

    const handleDelete = async () => {
        setIsLoading(true);
        await brandFormStore.deleteVenue(brandId, venue.id).then(res => {
            message.success(res?.message || "Deleted. It will take a few minutes to complete the process.");
            onDelete(venue.id);
            setIsLoading(false);
        }).catch(() => setIsLoading(false));
    };

    const handleUpdate = async () => {
        setIsLoadingUpdt(true);
        await brandFormStore.updateVenue(brandId, venue.id, venueName, venueStatus === true ? 1 : 0).then(res => {
            message.success(res?.message || "Updated. It will take a few minutes to complete the process.");
            setIsLoadingUpdt(false);
            setEditMode(false);
            refreshVenues();
        }).catch(() => setIsLoadingUpdt(false));
    };
    const style: React.CSSProperties = {
        display: "flex",
        gap: 10,
        padding: 5,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: isDeleted ? "not-allowed" : "unset",
        backgroundColor: isDeleted ? "#f5f5f5" : "unset"
    };
    return (
        <div style={style}>
            {!isEditMode && <label>(ID: <b>{venue.real_venue_id}</b>) {venue.name}</label>}
            {isEditMode && <>
                <Input
                    disabled={isDeleted}
                    placeholder={venue.name}
                    value={venueName}
                    onChange={e => setVenueName(e.target.value)}
                />
                <Switch checked={venueStatus} onChange={checked => setVenueStatus(checked)} />
            </>}
            <div style={{ display: "flex", gap: 10 }}>
                {!isEditMode && <Button disabled={isDeleted} type="dashed" style={{ margin: 0 }} onClick={() => setEditMode(true)}>Edit</Button>}
                {isEditMode && <Button disabled={isDeleted} loading={isLoadingUpdt} type="primary" style={{ margin: 0 }} onClick={handleUpdate}>Update</Button>}
                {!deleteDisabled && (
                    <Popconfirm disabled={isDeleted} title="Are you sure?" onConfirm={handleDelete}>
                        <Button disabled={isDeleted} loading={isLoading} type="danger" style={{ margin: 0 }}>Delete</Button>
                    </Popconfirm>
                )}
            </div>
        </div>
    );
};

export default VenueItem;

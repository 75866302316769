import { Chart } from "@app/pages/dashboard/Chart";
import { API_URL } from "@config/main";
import { ajax } from "@lib/ajax";
import Axios from "axios";
import { sortBy } from "lodash";
import { observer, useLocalStore } from "mobx-react";
import moment from "moment";
import * as React from "react";
import _ from "lodash";
import { ExpiringBrands } from "./ExpiringBrands";
import { Col, Row } from "antd";
import { BrandStats } from "./BrandStats";

interface ITotalData {
    date: string;
    count: number;
    list: string[];
}

export const Dashboard = observer(function Dashboard(): JSX.Element {

    const refineSubscription = (resp: any): any => {
        const data: any[] = [];
        resp.data.map((d: any) => {
            const t = _.find(data, ["date", d.month]);
            if (t) {
                t.count += d.total_amount;
                t.list.push(`${d.method}: ${d.total_amount}`);
            } else {
                data.push({
                    count: d.total_amount,
                    date: d.month,
                    list: [`${d.method}: ${d.total_amount}`]
                });
            }
        });

        return data;
    };

    const s = useLocalStore(() => ({
        totals: [] as ITotalData[],
        subscriptions: [] as ITotalData[],
        totalActiveBrands: [] as IBrand[],
        getDailyData(): Highcharts.SeriesLineOptions["data"] {
            return s.totals.map(d => ({
                x: moment(d.date).unix(),
                y: d.count,
                custom: [], //d.list,
            }));
        },
        getTodaysBrands(): number {
            return s?.totals[s.totals.length - 1]?.count;
        },
        getYesterdaysBrands(): number {
            return s?.totals[s.totals.length - 2]?.count;
        },
        getThisWeekBrands(): number {
            const ls = s?.totals.slice(s.totals.length - 7).map(x => x.list).flat();
            return _.uniq(ls).length;
        },
        getLastWeekBrands(): number {
            const ls = s?.totals.slice(s.totals.length - 14, s.totals.length - 7).map(x => x.list).flat();
            return _.uniq(ls).length;
        },
        getNewcomersData(): Highcharts.SeriesSplineOptions["data"] {
            const all = new Set<string>();
            const daily = s.totals.map(d => {
                const diff = d.list.filter(brand => !all.has(brand));
                diff.forEach(brand => all.add(brand));

                return {
                    x: moment(d.date).unix(),
                    month: moment(d.date).format("MMM YY"),
                    y: diff.length,
                    custom: diff
                };
            }).slice(1);

            const monthly = Object.values(daily.reduce((acc, x) => {
                const id = acc[x.month];
                if (id) {
                    id.y += x.y;
                    id.custom = id.custom.concat(x.custom);
                } else {
                    acc[x.month] = {
                        name: x.month,
                        y: x.y,
                        custom: x.custom,
                    };
                }
                return acc;
            }, {} as any)) as any[];

            return monthly;
        },
        getAbsentsData(): Highcharts.SeriesSplineOptions["data"] {
            const all = new Set<string>();
            let prevWeek: string[] = [];
            return Object.values(s.totals.reduce((acc, x: any) => {
                x.week = moment(x.date).format("YY.ww");
                const a = acc[x.week];
                if (a) {
                    a.list = _.uniq(a.list.concat(x.list));
                } else {
                    acc[x.week] = {
                        week: x.week,
                        list: x.list,
                        count: x.count
                    };
                }
                return acc;
            }, {} as any)).map((d: any) => {
                // const diff = [...all].filter(brand => !d.list.includes(brand));
                const diff = prevWeek.filter(brand => !d.list.includes(brand));
                prevWeek = d.list;
                d.list.forEach((brand: string) => all.add(brand));

                return {
                    name: d.week,
                    // x: moment(d.week, "YY.ww").unix(),
                    y: diff.length,
                    custom: diff
                };
            });
        },
        async loadSubscriptionTotals(): Promise<void> {
            const start = moment().subtract(1, "year").format("YYYY-MM");
            const end = moment().format("YYYY-MM");
            const resp = await ajax.get({ url: `${API_URL}transaction/monthly?date[]=${start}&date[]=${end}` });
            s.subscriptions = sortBy(refineSubscription(resp), "date");
        },
        getMonthlyData(): Highcharts.SeriesLineOptions["data"] {
            return s.subscriptions.map((d: any) => ({
                x: Date.parse(moment(d.date).format("YYYY-MM-DD")),
                y: d.count,
                custom: d.list,
            }));
        }
    }));

    React.useEffect(() => {
        s.loadSubscriptionTotals();
    }, []);

    return <div>
        <Row>
            <Col sm={24} md={12} lg={8}>
                {/*<Chart title="Monthly subscription payments" series={[{ type: "line", data: s.getMonthlyData() }]}*/}
                {/*    isMonthly={true} />*/}

                    <BrandStats />
            </Col>
            <Col sm={24} md={12} lg={8}>
                <ExpiringBrands justLeft={false} />
            </Col>
            <Col sm={24} md={12} lg={8}>
                <ExpiringBrands justLeft={true} />
            </Col>
        </Row>
    </div>;
});

import { getDefaultCountry } from "@app/actions/brand-actions";
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import VenuesContainer from "@app/components/VenuesContainer";
import { Actions, DATETIME_FORMAT, isDev } from "@app/config/main";
import { eSet, SellersList } from "@app/lib/utils";
import { ClientForm } from "@app/pages/clients/ClientForm/ClientForm";
import { sessionStore } from "@app/stores/SessionStore";
import { DeleteMenuOption } from "@pages/brands/BrandForm/DeleteMenuOption";
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Tabs,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { FormComponentProps } from "antd/lib/form";
import Spin from "antd/lib/spin";
import _, { isNil } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import ReactJson from "react-json-view";
import { deleteBtn, importMenuBtn } from "../BrandsPage/brandTableStore";
import { SubscriptionForm } from "../SubscriptionForm/SubscriptionForm";
import { brandFormStore } from "./BrandFormStore";

@observer
class BrandFormBare extends React.Component<FormComponentProps> {
    public componentDidMount(): void {
        brandFormStore.formUtils = this.props.form;
    }

    public handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err: Error | undefined, values: any) => {
                if (!err) {
                    brandFormStore.save();
                }
            }
        );
    };

    public clienLabel(): JSX.Element {
        return (
            <>
                <span>Client</span>
                <a
                    style={{ float: "right", marginLeft: 20 }}
                    onClick={brandFormStore.onCreateClientClick}
                >
                    Create New
                </a>
            </>
        );
    }

    private calculateUnpaidDays(brand: IBrand): number {
        const exp = moment(brand.subscription!.expired_at);
        const diff = exp.diff(moment(), "days");
        if (diff < 0) {
            const unpaidUsedDays = Math.abs(diff);
            if (unpaidUsedDays < 7) {
                return unpaidUsedDays;
            }
            return 7;
        }
        return 0;
    }

    private addXDaysToExpiration(brand: IBrand, x: string | number): void {
        x = parseInt(x as string);
        const unpaidDays = this.calculateUnpaidDays(brand);
        const daysAfterUnpaid = x - unpaidDays;

        brand.subscription!.expired_at = moment()
            .add(daysAfterUnpaid, "days")
            .format(DATETIME_FORMAT);
    }

    private setNewExpirationDate(brand: IBrand, m: any): void {
        brand.subscription!.expired_at = m.format(DATETIME_FORMAT);
    }

    private calculatePeriodPrice(brand: IBrand): number {
        if (!isNil(brand.subscription!.period_price)) {
            return brand.subscription!.period_price;
        }
        const packagePrice =
            this.getCountryPackage(brand.subscription?.package_id) ??
            brand.subscription?.packages[0];
        const monthlyPeriod = brand.subscription!.monthly_period;
        const periodPrice = packagePrice?.price || 0;
        const key = `price_${monthlyPeriod}_month` as const;
        // if ((packagePrice as any)[key]) {
        //     return (brand.subscription!.packages[0] as any)[key];
        // }
        return (packagePrice as any)[key] ?? monthlyPeriod * periodPrice;
    }

    public onRun = async (brand: IData): Promise<void> => {
        const actionName = brandFormStore.actionName;
        const brandTitle = (brand as any).title;
        let modal;
        let requestResp: any = {
            icon: "loading",
            title: `Running stock test for ${brandTitle}`,
            content: `Please wait until test has been finished`,
            okButtonProps: {
                disabled: true,
            },
        };
        if (actionName === "AnbarTest") {
            modal = Modal.info({
                width: 800,
                onOk: () => {},
                ...requestResp,
            });
        }
        try {
            const resp = await brandFormStore.onActionRun(brand);
            requestResp = {
                icon: "check",
                title: `Result of the test for ${brandTitle}`,
                content: (
                    <div>
                        <pre>{resp.data.output}</pre>
                    </div>
                ),
                okButtonProps: {
                    disabled: false,
                },
            };
            if (actionName === "clone") {
                setTimeout(() => {
                    window.open("https://clone.clopos.com");
                }, 5000);
            }
        } catch (e) {
            requestResp = {
                icon: "close",
                title: `Test has failed for ${brandTitle}`,
                content: `${e.msg || e.message}`,
                okButtonProps: {
                    disabled: false,
                },
            };
        }
        if (modal) {
            modal.update(requestResp);
        }
    };

    private submitButtons(): JSX.Element {
        return (
            <div>
                <Button
                    type="primary"
                    loading={brandFormStore.loading}
                    htmlType="submit"
                    style={{ marginRight: 5 }}
                >
                    Save
                </Button>
                <Button
                    type="default"
                    onClick={brandFormStore.hide}
                    htmlType="button"
                >
                    Cancel
                </Button>
            </div>
        );
    }

    public getCountryPackage(id?: number) {
        const defaultCountry = getDefaultCountry();
        const pkg =
            brandFormStore.packages?.find((p) => p.id === id) ??
            brandFormStore.packages[0];
        const countryPackage = pkg?.prices?.find(
            (p) => p?.country_code === defaultCountry
        );
        return countryPackage;
    }

    private isCurrencyDifferent() {
        const brand = brandFormStore.data;
        const packagePrice = this.getCountryPackage(
            brand.subscription?.package_id
        );
        if (packagePrice) {
            return brand.subscription?.currency !== packagePrice?.currency;
        }
        return false;
    }

    public render(): JSX.Element | null {
        const brand = brandFormStore.data;
        if (!brand) {
            return null;
        }
        const { getFieldDecorator } = this.props.form;

        const brandData = toJS(brandFormStore.data);
        const isCreate = _.isNil(brandData?.id);
        const isAZPartner = localStorage.getItem("admin_partnerId") === "1";

        const disableActionBtn =
            !brandFormStore.actionName ||
            (brandFormStore.actionName === "AnbarTest" &&
                !brandFormStore.actionStageName) ||
            (brandFormStore.actionName === "fillMenu" &&
                !brandFormStore.actionVenueId);
        const isStockTest =
            brandFormStore.actionName &&
            brandFormStore.actionName === "AnbarTest";
        const isCloneMenuAction =
            brandFormStore.actionName &&
            brandFormStore.actionName === "fillMenu";

        return (
            <>
                <FormDrawer
                    store={brandFormStore}
                    bodyStyle={{ padding: 0, height: "90vh" }}
                >
                    <ClientForm />
                    <SubscriptionForm />

                    <Tabs defaultActiveKey="1" style={{ height: "90vh" }}>
                        <Tabs.TabPane tab="General" key="1">
                            <Form
                                layout="horizontal"
                                onSubmit={this.handleSubmit}
                            >
                                <div>
                                    {/* <Divider orientation="left">Brand details</Divider> */}
                                    {/* name */}
                                    <Form.Item label="Subdomain">
                                        {getFieldDecorator("name", {
                                            initialValue: brand.name,
                                            rules: [
                                                {
                                                    required:
                                                        (sessionStore.role
                                                            ?.level || 0) > 3 &&
                                                        !_.isNil(
                                                            brandFormStore._data
                                                                .id
                                                        ),
                                                },
                                            ],
                                        })(
                                            <Input
                                                addonAfter={".clopos.com"}
                                                disabled={
                                                    (sessionStore.role?.level ||
                                                        0) <= 3 &&
                                                    !_.isNil(
                                                        brandFormStore._data.id
                                                    )
                                                }
                                                placeholder="Subdomain"
                                                onChange={eSet(brand, "name")}
                                            />
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Title">
                                        {getFieldDecorator("title", {
                                            initialValue: brand.title,
                                            rules: [{ required: true }],
                                        })(
                                            <Input
                                                placeholder="Title"
                                                onChange={eSet(brand, "title")}
                                            />
                                        )}
                                    </Form.Item>

                                    {/* <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label="Title">
                                            {getFieldDecorator("title", {
                                                initialValue: brand.title,
                                                rules: [{ required: true }]
                                            })(
                                                <Input placeholder="Title" onChange={eSet(brand, "title")} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Password">
                                            <Input.Password placeholder="input password" readOnly value={brand.client?.last_password} />
                                        </Form.Item>
                                    </Col>
                                </Row> */}

                                    {/* client_id */}
                                    <Form.Item label={this.clienLabel()}>
                                        {getFieldDecorator("client_id", {
                                            initialValue: brand.client?.email,
                                            rules: [{ required: true }],
                                        })(
                                            <Select
                                                placeholder="Select client"
                                                showSearch
                                                onChange={
                                                    brandFormStore.setClientId as any
                                                }
                                                optionFilterProp="children"
                                                notFoundContent={
                                                    brandFormStore.fetching ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                style={{ width: "100%" }}
                                                onSearch={
                                                    brandFormStore.onFetchClient
                                                }
                                                filterOption={false}
                                            >
                                                {brandFormStore.clients.map(
                                                    (c) => (
                                                        <Select.Option
                                                            key={c.id}
                                                            value={c.id}
                                                        >
                                                            {c.email}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {!sessionStore.user.partner?.parent_id ? (
                                        <Form.Item label={"Partner"}>
                                            {getFieldDecorator("partner_id", {
                                                initialValue: brand.partner_id,
                                                rules: [{ required: true }],
                                            })(
                                                <Select
                                                    placeholder="Select partner"
                                                    showSearch
                                                    onChange={
                                                        brandFormStore.setPartnerId as any
                                                    }
                                                >
                                                    {brandFormStore.partners.map(
                                                        (c) => (
                                                            <Select.Option
                                                                key={c.id}
                                                                value={c.id}
                                                            >
                                                                {c.name}{" "}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    ) : null}

                                    {isAZPartner && (
                                        <Form.Item label={"Seller"}>
                                            {getFieldDecorator("seller_id", {
                                                initialValue: brand.seller_id,
                                                rules: [{ required: false }],
                                            })(
                                                <Select
                                                    placeholder="Select seller"
                                                    showSearch
                                                    onChange={
                                                        brandFormStore.setSellerId as any
                                                    }
                                                >
                                                    {SellersList.map((c) => (
                                                        <Select.Option
                                                            key={c.id}
                                                            value={c.id}
                                                        >
                                                            {c.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    )}

                                    {this.submitButtons()}
                                </div>
                            </Form>
                        </Tabs.TabPane>
                        {!isCreate && (
                            <Tabs.TabPane tab="Venues" key="2">
                                <VenuesContainer brand={brand} />
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane tab="Subscription" key="3">
                            <Form
                                layout="horizontal"
                                onSubmit={this.handleSubmit}
                            >
                                <Form.Item label="Package">
                                    {getFieldDecorator("package", {
                                        initialValue: brand.subscription!
                                            .packages
                                            ? brand.subscription!.packages.filter(
                                                  (p) => p.type === "PACKAGE"
                                              )[0].id
                                            : undefined,
                                        rules: [{ required: true }],
                                    })(
                                        <Select
                                            placeholder="Select package"
                                            onChange={(dataId: any) => {
                                                brandFormStore.setPackageId(
                                                    dataId
                                                );
                                                const packagePrice =
                                                    this.getCountryPackage(
                                                        dataId
                                                    );
                                                // const packages = brand.subscription?.packages;
                                                // const countryPackage = packages
                                                //     ? packages[0]?.prices?.find(
                                                //           (p: IPackagePrice) => p?.country_code === brand.country
                                                //       )
                                                //     : null;
                                                if (
                                                    brand.subscription &&
                                                    packagePrice &&
                                                    !brand.id
                                                ) {
                                                    this.props.form.setFieldsValue(
                                                        {
                                                            period_price:
                                                                packagePrice.price,
                                                            // currency: packagePrice.currency,
                                                        }
                                                    );
                                                    brand.subscription.period_price =
                                                        packagePrice.price!;
                                                    brand.subscription.currency =
                                                        packagePrice.currency!;

                                                    // if (packagePrice?.price) {
                                                    //     eSetValue(
                                                    //         brand.subscription,
                                                    //         "period_price",
                                                    //         packagePrice?.price
                                                    //     );
                                                    // }
                                                    // if (packagePrice?.currency)
                                                    //     eSetValue(
                                                    //         brand.subscription,
                                                    //         "currency",
                                                    //         packagePrice?.currency
                                                    //     );
                                                }
                                            }}
                                        >
                                            {brandFormStore.packages.map(
                                                (p) => (
                                                    <Select.Option
                                                        key={p.id}
                                                        value={p.id}
                                                    >
                                                        {p.name}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Modules">
                                    {getFieldDecorator("modules", {
                                        initialValue: brand.subscription!
                                            .packages
                                            ? brand
                                                  .subscription!.packages.filter(
                                                      (p) => p.type === "MODULE"
                                                  )
                                                  .map((m) => m.id)
                                            : undefined,
                                        rules: [{ required: false }],
                                    })(
                                        <Select
                                            placeholder="Select module"
                                            mode="multiple"
                                            allowClear
                                            onChange={
                                                brandFormStore.setModulePackageId as any
                                            }
                                        >
                                            {brandFormStore.modulePackages.map(
                                                (p) => (
                                                    <Select.Option
                                                        key={p.id}
                                                        value={p.id}
                                                    >
                                                        {p.title}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Monthly period">
                                    {getFieldDecorator("monthly_period", {
                                        initialValue:
                                            brand.subscription!.monthly_period,
                                        rules: [{ required: true }],
                                    })(
                                        <Input
                                            placeholder="Monthly period"
                                            disabled={
                                                (sessionStore.role
                                                    ?.level as never) <= 3
                                            }
                                            addonAfter={"month"}
                                            onChange={eSet(
                                                brand.subscription,
                                                "monthly_period"
                                            )}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="Period price">
                                    {getFieldDecorator("period_price", {
                                        initialValue:
                                            brand.subscription?.period_price,
                                        rules: [{ required: true }],
                                    })(
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 8,
                                            }}
                                        >
                                            <Input
                                                placeholder="Period price"
                                                value={
                                                    brand.subscription
                                                        ?.period_price
                                                }
                                                addonAfter={
                                                    brand?.subscription
                                                        ?.currency || "AZN"
                                                }
                                                onChange={eSet(
                                                    brand.subscription,
                                                    "period_price"
                                                )}
                                            />
                                            {this.isCurrencyDifferent() && (
                                                <Button
                                                    type="default"
                                                    onClick={() => {
                                                        const packagePrice =
                                                            this.getCountryPackage(
                                                                brand
                                                                    .subscription
                                                                    ?.package_id
                                                            );
                                                        if (
                                                            packagePrice?.currency &&
                                                            brand.subscription
                                                        ) {
                                                            brand.subscription.currency =
                                                                packagePrice.currency;
                                                        }
                                                    }}
                                                >
                                                    Make{" "}
                                                    {
                                                        this.getCountryPackage()
                                                            ?.currency
                                                    }
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </Form.Item>

                                <Row gutter={16} style={{ marginBottom: 16 }}>
                                    <Col span={18}>
                                        Expiration:{" "}
                                        {moment(
                                            brand.subscription!.expired_at
                                        ).format(DATETIME_FORMAT)}
                                    </Col>
                                </Row>

                                {/* <Form.Item label="Expiration">
                                    <Row gutter={16}>
                                        <Col span={18}>
                                            {getFieldDecorator("expired_at", {
                                                initialValue: moment(brand.subscription!.expired_at),
                                                rules: [{ required: true }],
                                            })(
                                                <DatePicker
                                                    format={DATETIME_FORMAT}
                                                    onChange={this.setNewExpirationDate.bind(this, brand)}
                                                    disabled={(sessionStore.role?.level as never) <= 3}
                                                    style={{ width: "100%" }}
                                                />
                                            )}
                                        </Col>
                                        <Col span={6}>
                                            <Dropdown
                                                disabled={(sessionStore.role?.level as never) <= 3}
                                                trigger={["click"]}
                                                overlay={
                                                    <Menu
                                                        onClick={({ key }) =>
                                                            this.addXDaysToExpiration(brand, key as any)
                                                        }
                                                    >
                                                        <Menu.Item key={7}>7 days</Menu.Item>
                                                        <Menu.Item key={15}>15 days</Menu.Item>
                                                        <Menu.Item key={30}>30 days</Menu.Item>
                                                        <Menu.Item key={60}>60 days</Menu.Item>
                                                        <Menu.Item key={90}>90 days</Menu.Item>
                                                        <Menu.Item key={180}>180 days</Menu.Item>
                                                        <Menu.Item key={360}>360 days</Menu.Item>
                                                    </Menu>
                                                }
                                            >
                                                <Button>
                                                    Add <Icon type="down" />
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Form.Item> */}

                                {this.submitButtons()}
                            </Form>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Notes" key="4">
                            <Form
                                layout="horizontal"
                                onSubmit={this.handleSubmit}
                            >
                                <Form.Item label="Note">
                                    {getFieldDecorator("note", {
                                        initialValue: brand.note,
                                        rules: [{ required: false }],
                                    })(
                                        <TextArea
                                            style={{ height: 120 }}
                                            onChange={eSet(brand, "note")}
                                        />
                                    )}
                                </Form.Item>

                                {!isCreate && (
                                    <Form.Item label="Left reason">
                                        {getFieldDecorator("left_reason_note", {
                                            initialValue:
                                                brand.left_reason_note,
                                            rules: [{ required: false }],
                                        })(
                                            <TextArea
                                                style={{ height: 120 }}
                                                onChange={eSet(
                                                    brand,
                                                    "left_reason_note"
                                                )}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                                {this.submitButtons()}
                            </Form>
                        </Tabs.TabPane>
                        {!isCreate && (
                            <Tabs.TabPane
                                tab="Actions"
                                key="5"
                                style={{ height: "90vh" }}
                            >
                                <div>
                                    {/* <Divider orientation="left">Brand actions</Divider> */}
                                    {sessionStore.user.role?.level !== 3 && (
                                        <div>
                                            <Input.Group compact>
                                                <Select
                                                    style={{ width: 150 }}
                                                    placeholder="Select an action"
                                                    onChange={
                                                        brandFormStore.onActionChange
                                                    }
                                                >
                                                    {_.map(
                                                        Actions,
                                                        (
                                                            keys: string,
                                                            value: string
                                                        ) => (
                                                            <Select.Option
                                                                key={value}
                                                                value={value}
                                                            >
                                                                {keys}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                    {brand.id === 218 && (
                                                        <Select.Option value="AnbarTest">
                                                            Anbar Test
                                                        </Select.Option>
                                                    )}
                                                </Select>
                                                {isStockTest && (
                                                    <Select
                                                        style={{ width: 100 }}
                                                        placeholder="Select a stage"
                                                        onChange={
                                                            brandFormStore.onActionStageChanged
                                                        }
                                                    >
                                                        <Select.Option value="alpha">
                                                            Alpha
                                                        </Select.Option>
                                                        <Select.Option value="beta">
                                                            Beta
                                                        </Select.Option>
                                                        <Select.Option value="rc">
                                                            RC
                                                        </Select.Option>
                                                        <Select.Option value="prod">
                                                            Prod
                                                        </Select.Option>
                                                    </Select>
                                                )}
                                                {isCloneMenuAction && (
                                                    <Select
                                                        style={{ width: 200 }}
                                                        placeholder="Select a Restaurant"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        notFoundContent={
                                                            brandFormStore.fetching ? (
                                                                <Spin size="small" />
                                                            ) : null
                                                        }
                                                        onSearch={
                                                            brandFormStore.onBrandSearch
                                                        }
                                                        filterOption={false}
                                                        onChange={
                                                            brandFormStore.onActionVenueChanged
                                                        }
                                                    >
                                                        {brandFormStore.brands.map(
                                                            (c) => (
                                                                <Select.Option
                                                                    key={c.id}
                                                                    value={c.id}
                                                                >
                                                                    {c.title}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                                <Popconfirm
                                                    title="Are you sure?"
                                                    onConfirm={() =>
                                                        this.onRun(brand)
                                                    }
                                                    disabled={disableActionBtn}
                                                >
                                                    <Button
                                                        type="danger"
                                                        disabled={
                                                            disableActionBtn
                                                        }
                                                    >
                                                        run
                                                    </Button>
                                                </Popconfirm>
                                            </Input.Group>
                                        </div>
                                    )}
                                    {sessionStore.user.role?.level !== 3 && (
                                        <div
                                            style={{
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                            }}
                                        >
                                            <DeleteMenuOption />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        {importMenuBtn(brand)}
                                    </div>
                                </div>
                                {sessionStore.role?.level !== 3 && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: 0,
                                            right: 0,
                                        }}
                                    >
                                        {deleteBtn(brand)}
                                    </div>
                                )}
                            </Tabs.TabPane>
                        )}
                        {isDev ? (
                            <Tabs.TabPane tab="JSON" key="6">
                                <ReactJson
                                    src={brandData}
                                    name={brandFormStore.dataName}
                                />
                            </Tabs.TabPane>
                        ) : null}
                    </Tabs>
                </FormDrawer>
            </>
        );
    }
}

export const BrandForm = Form.create()(BrandFormBare);

import _ from "lodash";
import { ErrorType } from "@config/errors";
import {curry, isObject, VERSION} from "lodash";
import { ChangeEvent } from "react";
import countries from "@app/data/countries";

export async function attempt<T = any>(
    job: () => Promise<T> | T,
    handler?: (e: CustomError) => void,
    finallyCallback?: (e?: CustomError) => void
): Promise<T | undefined> {
    try {
        const res = await job();
        if (finallyCallback) {
            await finallyCallback();
        }
        return res;
    } catch (e) {
        if (handler) {
            await handler(e);
        }
        if (finallyCallback) {
            await finallyCallback();
        }
        errorCatcher(e);
        return;
    }
}

export async function errorCatcher(e: CustomError): Promise<void> {
    if (e.shouldBeHandled && !e.handled) {
        // log.runtimeError(e);
        console.log(e);
        console.trace();
    }
}

export function createError(data: any): CustomError {
    if (!data.message) {
        data.message = "Does not contain any error message";
    }
    const err = new Error() as CustomError;
    _.assign(err, data);
    return err;
}

export function shouldDisplayMessage(type: ErrorType): boolean {
    return ![ErrorType.IS_OFFLINE, ErrorType.SERVER_SIDE, ErrorType.AUTH].includes(type);
}

export const set = curry(_.set);
export const get = curry(_.rearg(_.get, [1, 0]));
export const eSet = curry((o: any, name: string, e: ChangeEvent<HTMLInputElement>) => o[name] = e.currentTarget.value);
export const eSetValue = curry((o: any, name: string, value: any) => o[name] = value);
export const eSetChecked = curry((o: any, name: string, e: ChangeEvent<HTMLInputElement>) =>
    o[name] = e.target.checked ? 1 : 0);

export const Countries = countries.map(c => c.value.toUpperCase());
export const CountriesAll: ICountry[] = countries;
export const PaymentTypes = ["MILLIKART", "YIGIM", "NAGD"];

export const SellersList = [
    {
        id: 0,
        name: "Rza",
    },
    {
        id: 1,
        name: "Teymur",
    },
    {
        id: 2,
        name: "Mahir",
    },
    {
        id: 3,
        name: "Emine",
    },
    {
        id: 4,
        name: "Office",
    },
    {
        id: 6,
        name: "Murad",
    },
    {
        id: 7,
        name: "Reyal",
    },
    {
        id: 8,
        name: "Kamran",
    },
    {
        id: 9,
        name: "Rahid",
    },
    {
        id: 10,
        name: "Siyasət",
    },
    {
        id: 11,
        name: "Ülvi",
    },
    {
        id: 12,
        name: "Anar",
    },
    {
        id: 13,
        name: "Ramil",
    },
    {
        id: 14,
        name: "Rəşid",
    },
    {
        id: 999,
        name: "Other",
    },
]

export const getEnumLabels = (eNum: object) => {
    return _.keys(eNum).filter(key =>  isNaN(parseInt(key)));
};

const BOT_TOKEN = "1660783450:AAE_41InH0YQDXMcx-famYVjETaVBpVlPWs";

export const chats = {
    terminal: -1001516403561,
    site: -373165666,
    money: -560604068,
    signal: -749896034,
    supportAnyDesk: -675200903,
};

export function sendTelegram(msg: string, payload?: any, chatId?: number): void {
    const cleanPayload = payload ? Object.keys(payload).map(key => `${key}: ${payload[key]}`).join("\n") : "";
    const message = msg + "\n V: " + VERSION + "\n" + cleanPayload;
    fetch(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`, {
        method: "post",
        body: JSON.stringify({chat_id: chatId || chats.terminal, text: message}),
        headers: {
            "Content-Type": "application/json"
        }
    }).catch(() => {
        /* ignore */
    });
}

import * as React from "react";
import { useState } from "react";
import { Button, message } from "antd";
import { ajax } from "@app/lib/ajax";
import { attempt } from "@app/lib/utils";
import { brandLoginApiUrl } from "@app/actions/brand-actions";

interface IProps {
    id: number;
}

export function LoginButton({ id }: IProps): JSX.Element {

    const [loading, setLoading] = useState(false);

    const onClick = () => {
        attempt(async () => {
            setLoading(true);
            const resp = await ajax.get({ url: brandLoginApiUrl(id) });
            window.open(resp.data.url);
        }, err => {
            message.error(err && err.message);
        }, () => {
            setLoading(false);
        });
    };

    return <Button loading={loading} icon="login" onClick={onClick}>
        Login
    </Button>;
}

import { ajax } from "../lib/ajax";
import { attempt } from "@app/lib/utils";
import { clone } from "lodash";
import { endpoints } from "@app/config/endpoints";

let _packages: IPackage[] = [];
const DEFAULT_PACKAGE_ID = 1;

// TODO: call only if authorised, and right after successful login
export function loadPackages(): Promise<void> {
    return attempt(async () => {
        const resp = await ajax.get({ url: endpoints.package, params: { with: ["prices"] } });
        _packages = resp.data;
    });
}

export function packages(): IPackage[] {
    return _packages;
}

export function packageById(id: number): IPackage | undefined {
    return _packages.find(r => r.id === id);
}

export function packageNames(): string[] {
    return _packages.map(pack => pack.name);
}

export const defaultPackage = () => clone(packageById(DEFAULT_PACKAGE_ID))!;

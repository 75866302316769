export enum BrandStage {
    PROD = "prod",
    BETA = "beta",
    RC = "rc",
    ALPHA = "alpha",
}

export enum BrandType {
    RESTAURANT = "RESTAURANT",
    SHOP = "SHOP",
}

export const BrandStageStatusOptions = ["DEFAULT", "TRIAL", "TEST"];

export const DEFAULT_STAGE = BrandStage.PROD;
export const DEFAULT_TYPE = BrandType.RESTAURANT;

(window as any).BrandStage = BrandStage;

import { ajax } from "@app/lib/ajax";
import moment from "moment"
import React, { useEffect, useState } from "react";
import { FC } from "react";

export const ExpiringBrands: FC<{ justLeft: boolean; }> = props => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const filters = [
            ["status", 5],
        ];
        if (props.justLeft) {
            filters.push(
                ["expired_at", "<", moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")],
                ["expired_at", ">", moment().subtract(60, "days").format("YYYY-MM-DD HH:mm:ss")],
            );
        } else {
            filters.push(
                ["expired_at", ">", moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")],
                ["expired_at", "<", moment().format("YYYY-MM-DD HH:mm:ss")]
            );
        }
        ajax.get({
            url: "/brand",
            params: {
                limit: 100,
                with: ["client", "subscription.packages", "transactions"],
                filters
            }
        }).then(resp => {
            const list = resp.data.sort((a: any, b: any) => {

                const aa = a.subscription.expired_at;
                const bb = b.subscription.expired_at;
                return aa > bb ? -1 : aa < bb ? 1 : 0;

            }).map((x: any) => {
                x.transactions = x.transactions.filter((tr: any) => tr.status === "SUCCESS");
                x.totalPayed = x.transactions.reduce((sum: number, tr: any) => sum + tr.amount, 0);
                return x;
            });

            setData(list);
        });
    }, []);

    return <div className="widget">
        <div className="widget-title" style={{ color: props.justLeft ? "red" : "gold" }}>
            {props.justLeft ? "Expired" : "Expiring"}
        </div>
        <div className="widget-body">
            <table className="table" >
                <thead>
                    <tr>
                        <th style={{ textAlign: "right" }}>#</th>
                        <th>Brand</th>
                        <th>Expired</th>
                        <th style={{ textAlign: "right" }}>Monthly</th>
                        <th style={{ textAlign: "right" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((brand: any) => {

                        return <tr key={brand.id}>
                            <td style={{ textAlign: "right" }}>{brand.id}</td>
                            <td>{brand.name}</td>
                            <td>{moment(brand.subscription.expired_at).fromNow()}</td>
                            <td style={{ textAlign: "right" }}>{brand.subscription.period_price} ₼</td>
                            <td style={{ textAlign: "right" }}>{brand.totalPayed} ₼</td>
                        </tr>;
                    })}
                </tbody>

            </table>
        </div>

    </div>;
};

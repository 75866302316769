import * as React from "react";
import "@app/app.scss";
import { MainLayout } from "@app/components/layout/MainLayout";
import { LoginLayout } from "@app/components/layout/LoginLayout";
import { sessionStore } from "@app/stores/SessionStore";
import { observer } from "mobx-react";
import { initApp, appStore } from "@app/stores/AppStore";

@observer
export class App extends React.Component {

    constructor(props: any) {
        super(props);
        initApp();
    }

    public render(): JSX.Element {
        return appStore.isReady 
            ?  (sessionStore.isLoggedIn ? <MainLayout /> : <LoginLayout />) 
            : <div>Loading...</div>;
    }
}

import axios, {AxiosInstance, Method} from "axios";
import * as _ from "lodash";
import { API_URL } from "../config/main";
import { ErrorType } from "../config/errors";
import {createError, sendTelegram} from "./utils";
import { message } from "antd";
import { endSession } from "@app/stores/SessionStore";
import qs from "qs";

export class Ajax {
    public static instances: Ajax[] = [];
    public loading = false;
    private axios: AxiosInstance;

    constructor() {
        Ajax.instances.push(this);
        this.axios = axios.create();
        this.axios.defaults.baseURL = API_URL;
        this.setBaseUrl(API_URL);
        // this.addDefaultHeader("pragma", "no-cache");
        // this.addDefaultHeader("cache-control", "no-cache");
    }

    public setBaseUrl(url: string): void {
        this.axios.defaults.baseURL = url;
    }

    public addDefaultHeader(name: string, value: string): void {
        this.axios.defaults.headers.common[name] = value;
    }

    public setToken(token: string): void {
        this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    public async load<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        if (!conf.params) {
            conf.params = {};
        }
        if (conf.method === "put") {
            conf.method = "post";
            conf.params._method = "put";
        }

        if (conf.method === "get" && conf.data) {
            _.assign(conf.params, conf.data);
            conf.data = {};
        }

        if (!_.isEmpty(conf.params)) {
            conf.url += `?${decodeURIComponent(qs.stringify(conf.params, {encode: false}))}`;
            conf.params = null;
        }
        conf.timeout = 3e4;
        try {
            const resp = await this.axios.request(conf);
            if (!conf.ignoreSuccess && !resp.data.success) {
                const err = createError({ response: resp });
                if (resp.data.error) {
                    _.assign(err, resp.data.error[0]);
                }
                throw err;
            }
            return resp.data;
        } catch (e) {
            if (e.code === "ECONNABORTED") {
                // log.error({ type: "TimeOut", msg: `Request did not receive any response in - ${conf.timeout}ms` });
                console.error({ type: "TimeOut", msg: `Request did not receive any response in - ${conf.timeout}ms` });
            }

            if (!e.response) {
                throw createError({ code: ErrorType.IS_OFFLINE });
            }
            const err = createError(e.response.data.error && e.response.data.error[0] || { type: "Empty error" });

            if (err.type !== "server_side") {
                message.error(err.message);
                if (err.type === "auth") {
                    endSession();
                    message.error(e.message);
                } else {
                    // this case should be handled on catch
                    err.shouldBeHandled = true;
                }
            } else {
                message.error("Ups.. Server error happend. Call devs");

                sendTelegram("Ups.. Server error happend. Call devs", {
                    domain: location.href,
                    ...conf,
                    Error: err.message || err
                });
            }

            throw err;
        }
    }

    public get<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "get";
        return this.load(conf);
    }

    public post<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "post";
        return this.load(conf);
    }

    public put<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "put";
        return this.load(conf);
    }

    public delete<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "delete";
        return this.load(conf);
    }
}

export interface IAjaxConf {
    url: string;
    method?: Method;
    data?: any;
    params?: any;
    auth?: any;
    paramsSerializer?: any;
    timeout?: number;
    headers?: any[];
    ignoreSuccess?: boolean;
}

export const ajax: Ajax = new Ajax();

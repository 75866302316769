import * as React from "react";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { endpoints } from "@app/config/endpoints";
import { Icon, Tooltip } from "antd";
import { clientFormStore } from "../ClientForm/ClientFormStore";
import { ClientForm } from "../ClientForm/ClientForm";
import { DataProvider } from "@app/components/DataTable/DataProvider";

export const clientTableStore = new DataTableStore<IClient>({
    dataProvider: new DataProvider({
        url: endpoints.client,
    }),
    formStore: () => clientFormStore,
    form: ClientForm,
    columnsManager: {
        hiddenColumns: ["created_at"]
    },
    filters: [
        {
            name: "email",
            label: "E-mail",
            type: "dropdown-search",
            endpoint: "client",
            values: {},
            defaultValue: "___"
        }
    ],
    columns: [
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center",
            width: 200,
            render: renderEmail
        },
        {
            title: "First name",
            dataIndex: "first_name",
            key: "first_name",
            align: "center",
            width: 100
        },
        {
            title: "Last name",
            dataIndex: "last_name",
            key: "last_name",
            align: "center",
            width: 100
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            align: "center",
            width: 100,
            // render: (status) => <ClientStatusTag status={status} />
        },
        {
            title: "Last login",
            dataIndex: "login_at",
            key: "login_at",
            align: "center",
            width: 100
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 100
        },
        {
            title: "Actions",
            key: "actions",
            width: 200,
            render: actions
        },
    ]
});

const editBtn = editButton(clientTableStore);
const deleteBtn = deleteButton(clientTableStore);

function actions(data: any): JSX.Element {
    return <>
        {editBtn(data)}
        {deleteBtn(data)}
    </>;
}

function renderEmail(email: string, client: IClient): JSX.Element {
    const props = client.email_verified_at ? {
        type: "check-circle",
        title: "Email is verified",
        style: { color: "green", marginLeft: 10 }
    } : {
            type: "close-circle",
            title: "Email is not verified",
            style: { color: "red", marginLeft: 10 }
        };
    return <span>
        {email}
        <Tooltip title={props.title}>
            <Icon {...props} />
        </Tooltip>
    </span>;
}

import { dummyUser, refineUserFromApi } from "@app/actions/user-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { userTableStore } from "../UsersPage/userTableStore";
import { observable } from "mobx";

export class UserFormStore extends FormDrawerStore<IUser> {

    @observable public _data = dummyUser();
    public autofocus = "username";
    public dataName = "user";
    public dummyDataFn = dummyUser;
    public apiUrl = endpoints.user;

    get tableStore(): DataTableStore {
        return userTableStore;
    }
    set tableStore(s: DataTableStore) {
        
    }

    public afterSave = (d: IUser) => {
        if (this.tableStore) {
            this.tableStore.dataProvider.updateLocalCopyOfData(refineUserFromApi(d));
        }
    }
}

export const userFormStore = new UserFormStore;

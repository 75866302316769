import {DataTable} from "@app/components/DataTable/DataTable";
import {observer} from "mobx-react";
import * as React from "react";
import {transactionTableStore} from "@pages/transactions/TransactionsPage/transactionTableStore";
import moment from "moment";

@observer
export class TransactionsPage extends React.Component {

    public componentDidMount(): void {
        // noinspection JSIgnoredPromiseFromCall
        transactionTableStore.dataProvider.loadData();
    }

    public get calculateLast30DaysTotalRevenue(): number {
        const totalAmount = transactionTableStore.dataProvider.list
            .filter(t =>  moment().diff(moment(t.created_at), "days") <= 30)
                .reduce((total, trans) => trans.amount + total, 0);
        return parseFloat(totalAmount.toString());
    }

    public render(): JSX.Element {
        return <div>
            <h2>Transactions</h2>
            <div>
                <DataTable store={transactionTableStore} noCreate bordered />
            </div>
        </div>;
    }
}

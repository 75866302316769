import {BrandsPage} from "@app/pages/brands/BrandsPage/BrandsPage";
import {ClientsPage} from "@app/pages/clients/ClientsPage/ClientsPage";
import {UsersPage} from "@app/pages/users/UsersPage/UsersPage";
import {Dashboard} from "@app/pages/dashboard/dashboard";
import {RouteComponentProps} from "react-router-dom";
import {TransactionsPage} from "@pages/transactions/TransactionsPage/TransactionsPage";
import * as React from "react";
import { BrandTerminalsPage } from "@app/pages/brands/BrandsPage/BrandTerminalsPage/BrandTerminalsPage";

export interface IRoute {
    path: string;
    main: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    exact?: boolean;
}

const exact = true;

export const routes: IRoute[] = [
    {path: "/", main: Dashboard, exact},
    {path: "/users", main: UsersPage},
    {path: "/brands", main: BrandsPage, exact},
    {path: "/brand/:brandId/terminals", main: BrandTerminalsPage, exact},
    {path: "/clients", main: ClientsPage},
    {path: "/transactions", main: TransactionsPage},
];

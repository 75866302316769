import * as React from "react";
import { Form, Input, Button, Select } from "antd";
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import _ from "lodash";
import { eSet } from "@app/lib/utils";
import { userFormStore } from "@app/pages/users/UserForm/UserFormStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { roles, roleById } from "@app/data/roles";
import { FormComponentProps } from "antd/lib/form";
import JSONView from "@app/components/JSONView";

@observer
class UserFormBare extends React.Component<FormComponentProps> {

    public componentDidMount(): void {
        userFormStore.formUtils = this.props.form;
    }

    public onRoleChange(id: number): void {
        userFormStore.data.role = roleById(id);
    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                userFormStore.save();
            }
        });

    }

    public render(): JSX.Element {
        const user = userFormStore.data;
        const { getFieldDecorator } = this.props.form;
        return <>
            <FormDrawer store={userFormStore}>
                <Form layout="vertical" onSubmit={this.handleSubmit} >
                    <Form.Item label="E-mail" >
                        {getFieldDecorator("email", {
                            initialValue: user.email,
                            rules: [{ required: true }]
                        })(
                            <Input placeholder="E-mail" onChange={eSet(user, "email")} />
                        )}
                    </Form.Item>

                    <Form.Item label="Username" >
                        {getFieldDecorator("username", {
                            initialValue: user.username,
                            rules: [{ required: true }]
                        })(
                            <Input placeholder="Username" onChange={eSet(user, "username")} />
                        )}
                    </Form.Item>

                    <Form.Item label="Password" >
                        {getFieldDecorator("password", {
                            rules: [{ min: 5, required: !user.id }],
                        })(
                            <Input placeholder="Password" type="password" onChange={eSet(user, "password")} />
                        )}
                    </Form.Item>

                    <Form.Item label="Role">
                        <Select defaultValue={_.get(user, "role.id")} onChange={this.onRoleChange as any}>
                            {roles().map(role => <Select.Option key={role.id} value={role.id}>
                                {role.title}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>

                    <JSONView src={toJS(user)} collapsed={1} name={userFormStore.dataName} />

                    <Button type="primary" loading={userFormStore.loading} htmlType="submit" style={{ marginRight: 5 }}>
                        Save
                    </Button>
                    <Button type="default" onClick={userFormStore.hide} htmlType="button">Cancel</Button>

                </Form>
            </FormDrawer>
        </>;
    }
}

export const UserForm = Form.create()(UserFormBare);

import * as React from "react";
import { BrandStatus } from "@app/config/enums";
import { Tag } from "antd";

const colors: any = {
    [BrandStatus.ACTIVE]: "green",
    [BrandStatus.PENDING]: "orange",
    [BrandStatus.DELETE]: "red",
    [BrandStatus.DELETING]: "red",
    [BrandStatus.CLOSE]: "magenta",
    [BrandStatus.EXPIRED]: "volcano",
    [BrandStatus.FREEZE]: "blue",
    [BrandStatus.OUT_OF_SERVICE]: "red",
};

export function BrandStatusTag({status}: { status: BrandStatus }): JSX.Element {
    return <Tag color={colors[status]} style={{fontSize: 12}}>
        {BrandStatus[status as any]}
    </Tag>;
}

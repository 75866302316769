import { dummyBrand } from "@app/actions/brand-actions";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { endpoints } from "@app/config/endpoints";
import { ajax } from "@app/lib/ajax";
import { clientFormStore } from "@app/pages/clients/ClientForm/ClientFormStore";
import { action, observable, toJS } from "mobx";
import { brandTableStore } from "../BrandsPage/brandTableStore";
import { attempt } from "@lib/utils";
import { actionByName, getData, saveData } from "@lib/api-utils";
import * as React from "react";
import { sendAction } from "@app/actions/terminal-actions";
import JSONFormData from "json-form-data";
import { message } from "antd";
import _ from "lodash";
import { sessionStore } from "@app/stores/SessionStore";

class BrandFormStore extends FormDrawerStore<IBrand> {
    @observable public _data: IBrand;
    public autofocus = "name";
    public dataName = "brand";
    public dummyDataFn = dummyBrand;
    public apiUrl = endpoints.brand;
    @observable public fetching: boolean = false;
    @observable public showDeleteOption: boolean = false;

    @observable public clients: IClient[] = [];
    @observable public brands: IBrand[] = [];
    @observable public packages: IPackage[] = [];
    @observable public venues: IVenue[] = [];
    @observable public modulePackages: IPackage[] = [];
    @observable public partners: IPartner[] = [];
    @observable public selectedProductTypes: string[] = [];
    @observable public actionName: string = "";
    @observable public actionStageName: string = "";
    @observable public actionVenueId: number;
    public formDrawerProps = {
        width: 650,
    };

    @observable public productTypes: string[] = [
        "GOODS",
        "INGREDIENT",
        "DISH",
        "PREPARATION",
        "MODIFICATORS",
        "CATEGORIES",
        "TIMER",
    ];

    public beforeShow = async (): Promise<void> => {
        this.showDeleteOption = false;
        this.actionName = "";
        await Promise.all([
            this.loadClients(),
            this.loadPackages(),
            this.loadModulePackages(),
            this.loadBrands(),
            this.loadVenues(),
            this.loadDealers(),
        ]);
        // subscriptionFormStore.brand = this.data;
    };

    // @ts-ignore
    get tableStore(): DataTableStore {
        return brandTableStore;
    }
    set tableStore(s: DataTableStore) {}

    public async loadClients(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.client,
            params: {
                sort: ["id", "=", -1],
            },
        });
        this.clients = resp.data;
    }

    public async loadDealers(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.partners,
            params: {
                filters: [
                    ["type", "=", "DEALER"],
                    ["parent_id", "=", sessionStore.user.partner_id],
                ],
                sort: ["id", "=", -1],
            },
        });
        this.partners = [sessionStore.user.partner, ...resp.data];
    }

    public async loadBrands(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.brand,
            params: {
                sort: ["id", "=", -1],
            },
        });
        this.brands = resp.data.filter(
            (brand: IBrand) => brand.id !== this._data.id
        );
    }

    public async loadPackages(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                filters: [["type", "PACKAGE"]],
                with: ["prices"],
            },
        });
        this.packages = resp.data.filter(
            (p: IPackage) => !p.name.endsWith("pro")
        );
    }

    public async loadVenues(): Promise<IVenue[]> {
        if (this._data.id) {
            const resp = await ajax.get({
                url: `brand/${this._data.id}/venues`,
            });
            this.venues = resp?.data || [];
            return resp?.data || [];
        }
        return new Promise(() => null);
    }

    public async createVenue(
        brandId: number | undefined = this._data.id,
        name: string
    ): Promise<IApiResponse<any>> {
        return await ajax.post({
            url: `brand/${brandId}/venues`,
            data: {
                name,
            },
        });
    }

    public async deleteVenue(
        brandId: number | undefined = this._data.id,
        venueId: number
    ): Promise<IApiResponse<any>> {
        return await ajax.delete({
            url: `brand/${brandId}/venues/${venueId}`,
        });
    }

    public async updateVenue(
        brandId: number | undefined = this._data.id,
        venueId: number,
        name: string,
        status: number
    ): Promise<IApiResponse<any>> {
        return await ajax.post({
            url: `brand/${brandId}/venues/${venueId}?_method=PUT`,
            data: {
                name,
                status,
            },
        });
    }

    public async loadModulePackages(): Promise<void> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                filters: [["type", "MODULE"]],
            },
        });
        this.modulePackages = resp.data;
    }

    @action
    public onCreateClientClick = () => {
        clientFormStore.onCreateClick();
        clientFormStore.afterSaveCallback = action((client: IClient) => {
            this.clients.push(client);
            this._data.client = client;
            this._data.client_id = client.id!;
            this._data.country = client.country;
            this.formUtils!.setFieldsValue({ client_id: client.id });
        });
    };

    @action
    public setClientId = (id: number) => {
        this.data.client_id = id;
        this.data.client = _.find(this.clients, { id });
    };

    @action
    public setSellerId = (id: number) => {
        this.data.seller_id = id;
    };

    @action
    public setPartnerId = (id: number) => {
        this.data.partner_id = id;
    };

    @action
    public setDeletingProductType = (type: string[]) => {
        this.selectedProductTypes = type;
    };

    public onDeleteProduct = async (): Promise<void> => {
        this.loading = true;
        await attempt(async () => {
            const params = {
                type: toJS(this.selectedProductTypes),
            };
            await actionByName(this.apiUrl, this.data.id!, "ClearMenu", params);
            this.selectedProductTypes = [];
        });
        this.loading = false;
    };

    public onFetchClient = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1],
        };
        if (value) {
            params = {
                filters: [["email", "=", value]],
            };
        }
        const resp = await ajax.get({
            url: endpoints.client,
            params,
        });
        this.fetching = false;
        this.clients = resp.data;
    }, 500);

    public onMenuSave = async (): Promise<void> => {
        attempt(
            async () => {
                this.loading = true;
                const url = `${this.apiUrl}/${this.data.id}/action/ProductImport`;
                const resp = await saveData(
                    { url },
                    {
                        content: this.data.importedMenu,
                    }
                );
                if (resp.success) {
                    await saveData(
                        { url },
                        {
                            content: this.data.importedMenu,
                            passed: true,
                        }
                    );
                    this.loading = false;
                    this.hideModal();
                }
            },
            (err) => {
                if (err.field && this.formUtils) {
                    _.forEach(err.field, (value, name) => {
                        this.formUtils!.setFields({
                            [name]: { errors: value.map(Error) },
                        });
                    });
                    this.fieldsWithError = err.field;
                }
            },
            () => {
                this.loading = false;
            }
        );
    };

    public onMenuXLSSave = async (): Promise<void> => {
        attempt(
            async () => {
                this.loading = true;
                const url = `${this.apiUrl}/${this.data.id}/action/ExcelProductImport`;
                const formData = JSONFormData({
                    file: this.data.importedExcelMenu,
                });

                await ajax
                    .post({
                        url,
                        data: formData,
                    })
                    .then(() => {
                        message.success("Success!");
                        this.hideModalSecondary();
                        this.loading = false;
                    })
                    .catch((ex) => {
                        this.loading = false;
                    });
            },
            (err) => {
                this.loading = false;
            },
            () => {
                this.loading = false;
            }
        );
    };

    public onExcelMenuChange = (file: File) => {
        this.data.importedExcelMenu = file;
    };

    public onMenuChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.data.importedMenu = e.currentTarget.value;
    };

    public showDelete = () => {
        this.showDeleteOption = !this.showDeleteOption;
    };

    @action
    public setPackageId = (id: number) => {
        const newPackage = _.find(this.packages, { id });
        if (newPackage) {
            const modules = toJS(this.data.subscription!.packages).filter(
                (p) => p.type === "MODULE"
            );
            this.data.subscription!.packages = [newPackage, ...modules];
        }
    };

    @action
    public setModulePackageId = (ids: number[]) => {
        this.data.subscription!.modules = ids;
    };

    public afterSave = (d: IBrand) => {
        if (this.tableStore) {
            // keeping old data before updating it
            const oldData = _.cloneDeep(
                this.tableStore.dataProvider.list.find((dp) => dp.id === d.id)
            );

            // updating stale data on table
            this.tableStore.dataProvider.updateLocalCopyOfData(d, true);

            // checking if old data stage is diff than new data stage
            if (oldData && oldData.stage !== d.stage) {
                console.log("saved requesting brand's terminals");
                this.updateTerminalsStage(d);
            }
            // await sendAction(this.props.terminal, key as any);
        }
    };

    public updateTerminalsStage = async (d: IBrand): Promise<void> => {
        attempt(
            async () => {
                const url = `${this.apiUrl}/${d.id}/terminal`;
                const resp = await getData({ url });
                if (resp.success) {
                    const terminals = resp.data as ITerminal[];
                    terminals
                        .filter((t) => t.device_id)
                        .forEach((terminal) => {
                            sendAction(
                                { ...terminal, brand: d.name },
                                "switch-stage"
                            );
                        });
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    public onActionChange = (value: string) => {
        this.actionName = value;
    };

    public onActionStageChanged = (value: string) => {
        this.actionStageName = value;
    };

    public onActionVenueChanged = (value: number) => {
        this.actionVenueId = value;
    };

    public onBrandSearch = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1],
        };
        if (value) {
            params = {
                filters: [["name", value]],
            };
        }
        const resp = await ajax.get({
            url: endpoints.brand,
            params,
        });
        this.fetching = false;
        this.brands = resp.data.filter(
            (brand: IBrand) => brand.id !== this._data.id
        );
    }, 500);

    public prepareActionPayload = () => {
        switch (this.actionName) {
            case "AnbarTest":
                return { stage: this.actionStageName };
            case "fillMenu":
                return { from: this.actionVenueId };
            default:
                return undefined;
        }
    };

    public onActionRun = async (d: IData) => {
        const actionParams = this.prepareActionPayload();
        return await this.tableStore.dataProvider.clearAction(
            d,
            this.actionName,
            actionParams
        );
    };
}

export const brandFormStore = new BrandFormStore();

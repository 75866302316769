import { DataTableStore } from "@app/components/DataTable/DataTableStore";
// import { ActionButtons } from "./ActionButtons";
import { endpoints } from "@app/config/endpoints";
import * as React from "react";
import { editButton, deleteButton } from "@app/components/DataTable/actions";
import { terminalFormStore } from "../../TerminalForm/TerminalFormStore";
import { TerminalForm } from "../../TerminalForm/TerminalForm";
import { TerminalActionButton } from "@app/pages/terminals/TerminalsPage/TerminalActionButton";
import { DataProvider } from "@app/components/DataTable/DataProvider";
import { TerminalDataViewer } from "@app/pages/terminals/TerminalsPage/TerminalDataViewer/TerminalDataViewer";
import {Devices} from "@app/data/devices";

export const brandTerminalsTableStore = new DataTableStore<ITerminal>({
    dataProvider: new DataProvider({
        url: endpoints.brand,
        with: ["client"],
    }),
    formStore: () => terminalFormStore,
    form: TerminalForm,
    columnsManager: {
        hiddenColumns: []
    },
    columns: [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            width: 100
        },
        {
            title: "Device ID",
            dataIndex: "device_id",
            key: "device_id",
            align: "center",
            width: 300
        },
        {
            title: "Remote Control ID",
            dataIndex: "devices_setting",
            key: "remote_control_id",
            align: "center",
            width: 300,
            render: remoteControlId
        },
        {
            title: "Accessible by",
            dataIndex: "device_type",
            key: "device_type",
            align: "center",
            width: 300,
            render: deviceType
        },
        {
            title: "Password",
            dataIndex: "password",
            key: "password",
            align: "center",
            width: 100
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 100
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 100
        },
        {
            title: "Updated at",
            dataIndex: "updated_at",
            key: "updated_at",
            align: "center",
            width: 100
        },
        {
            title: "Actions",
            key: "actions",
            width: 300,
            render: actions
        },
    ]
});

const editBtn = editButton(brandTerminalsTableStore);
const deleteBtn = deleteButton(brandTerminalsTableStore);

function actions(data: any): JSX.Element {
    return <>
        {editBtn(data)}
        {deleteBtn(data)}
        <TerminalActionButton terminal={data} />
        <TerminalDataViewer brand={data.brand} deviceId={data.device_id}/>
    </>;
}

function deviceType(data: any): JSX.Element {
    return <>
        {Devices[data]}
    </>;
}

function remoteControlId(d: {remote_control_id?: string} | null): string {
    return d?.remote_control_id || "";
}

import * as React from "react";
import {Button, Table} from "antd";
import {TableProps} from "antd/lib/table/interface";
import {DataTableStore} from "@app/components/DataTable/DataTableStore";
import {observer} from "mobx-react";
import {toJS} from "mobx";
import {ColumnsManager} from "./ColumnsManager";
import {ErrorBoundary} from "../misc/ErrorBoundary";
import {get} from "lodash";
import {DataTableFilter} from "@app/components/DataTable/DataTableFilter";

interface IDataTableProps<T extends IData> extends TableProps<T> {
    store: DataTableStore<T>;
    extraHeaderElements?: React.ReactNode;
    noCreate?: boolean
}

@observer
export class DataTable<T extends IData> extends React.Component<IDataTableProps<T>> {

    constructor(props: IDataTableProps<T>) {
        super(props);
    }

    public tableProps(): TableProps<T> {
        const { store, ...rest } = this.props;

        return {
            rowKey: "id",
            useFixedHeader: true,
            loading: store.dataProvider.loading,
            dataSource: toJS(store.dataProvider.list),
            columns: toJS(store.columns),
            pagination: toJS(store.dataProvider.pagination),
            ...rest
        };
    }

    public renderFilters(): React.ReactNode {
        return this.props.store.config.filters && this.props.store.config.filters.map(filter =>
            <DataTableFilter key={`${filter.name}-${filter.label}`} store={this.props.store} filter={filter}/>);
    }

    public createButton(): JSX.Element | null {
        const DataForm = this.props.store.config.form;
        const formStore = this.props.store.config.formStore && this.props.store.config.formStore();
        const { store } = this.props;
        return <>
            {store.config.reloadBtn && <Button icon="reload" onClick={store.dataProvider.loadData} />}
            {formStore && !this.props.noCreate ? <Button onClick={formStore.onCreateClick} type="primary" icon="plus"
                style={{ marginBottom: 10, marginRight: 5 }}>Create</Button> : null}
            {DataForm && <DataForm />}
        </>;
    }

    public columnsManager(): JSX.Element | null {
        if (this.props.store.config.columnsManager) {
            const columns = get(this.props.store.config.columnsManager, "hiddenColumns", []);
            return <ColumnsManager store={this.props.store} defaultHiddenColumns={columns} />;
        } else {
            return null;
        }
    }

    public render(): JSX.Element {

        return <>
            {this.createButton()}
            {this.columnsManager()}
            {this.renderFilters()}
            <Table {...this.tableProps()} />
        </>;
    }

}

import {ColumnProps} from "antd/lib/table";
import {action, computed, observable} from "mobx";
import {FormDrawerStore} from "../FormDrawer/FormDrawerStore";
import {DataProvider} from "./DataProvider";
import _ from "lodash";
import {endpoints} from "@config/endpoints";
type Endpoint = keyof typeof endpoints;

export type IDataTableFilters = Array<{
    name: string;
    type: "dropdown" | "checkbox" | "input" | "version-dropdown" | "app-v-dropdown" | "dropdown-search" | "dropdown-search-key-value" | "dropdown-multi";
    values?: Record<string, string>;
    defaultValue?: string | boolean | number;
    isLocal?: boolean;
    label?: string;
    placeholder?: string;
    endpoint?: Endpoint
}>;

interface IDataTableConfig<T extends IData> {
    dataProvider: DataProvider<T>;
    columns: Array<ColumnProps<T>>;
    form?: React.ComponentClass;
    formStore?: () => FormDrawerStore<T>;
    columnsManager?: boolean | {
        hiddenColumns: Array<keyof T>
    };
    reloadBtn?: boolean;
    filters?: IDataTableFilters;
}

export class DataTableStore<T extends IData = any> {

    @observable public columns: Array<ColumnProps<T>> = [];
    @observable public dataProvider: DataProvider<T>;
    @observable public config: IDataTableConfig<T>;

    constructor(config: IDataTableConfig<T>) {
        this.config = _.assign({}, {
            reloadBtn: true,
        }, config);
        this.columns = this.config.columns;
        this.dataProvider = this.config.dataProvider;
    }

    @action public onEditClick = (d: T) => {
        if (this.config.formStore) {
            const formStore = this.config.formStore();
            formStore.setData(formStore.dummyDataFn(d));
            formStore.show();
        }
    };

    @action public showModal = (d: T) => {
        if (this.config.formStore) {
            const formStore = this.config.formStore();
            formStore.showModal(d);
        }
    }

    @action public showModalSecondary = (d: T) => {
        if (this.config.formStore) {
            const formStore = this.config.formStore();
            formStore.showModalSecondary(d);
        }
    }

    @computed get showCreateButton(): boolean {
        return !!this.config.form && !!this.config.formStore;
    }

}

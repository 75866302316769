import { getDefaultCountry } from "@app/actions/brand-actions";
import { endpoints } from "@app/config/endpoints";
import { ajax } from "@app/lib/ajax";
import { deleteData, saveData } from "@app/lib/api-utils";

const url = endpoints.client;
export const saveClient = (client: IClient) => saveData({ url }, client);

export const deleteClient = (client: IClient | number) => deleteData(url, client);

export const refineClientFromApi = (d: IClient) => {
    return d;
};

export const getClientById = async (id: number): Promise<IClient> => {
    const resp = await ajax.get({ url: `${url}/${id}` });
    return refineClientFromApi(resp.data);
};

export const dummyClient = (client?: IClient): IClient => {
    return {
        first_name: "",
        last_name: "",
        email: "",
        country: getDefaultCountry(),
        partner_id: null,
        last_password: "",
        phone: "",
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        ...client,
    };
};

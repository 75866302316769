export interface INavListItem {
    label: string;
    to: string;
    icon: string;
    children?: INavListItem[];
}

export const navList: INavListItem[] = [
    {label: "Dashboard", to: "/", icon: "home"},
    {label: "Users", to: "/users", icon: "team"},
    {label: "Clients", to: "/clients", icon: "contacts"},
    {label: "Brands", to: "/brands", icon: "shop"},
    {label: "Transactions", to: "/transactions", icon: "transaction"},
];

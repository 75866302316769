import { FormDrawerStore } from "@app/components/FormDrawer/FormDrawerStore";
import { observable } from "mobx";
import { dummySubscription } from "@app/actions/brand-actions";
import _ from "lodash";
import { brandFormStore } from "../BrandForm/BrandFormStore";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";

class SubscriptionFormStore extends FormDrawerStore<ISubscription> {

    @observable public _data = {} as any;
    public autofocus = "";
    public dataName = "subscription";
    public dummyDataFn = dummySubscription;
    public apiUrl = "";

    public get tableStore(): any {
        return;
    }

    set tableStore(s: DataTableStore) {
        
    }
    // @observable public brand?: IBrand;

    public save = async (): Promise<void> => {
        if (brandFormStore.data.subscription!.package_id === this.data.package_id) {
            brandFormStore.data.subscription = _.clone(this.data);
        }
        this.hide();
    }
}

export const subscriptionFormStore = new SubscriptionFormStore;

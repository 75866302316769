import * as React from "react";
import { ChangeEvent, KeyboardEvent, ReactNode } from "react";
import { Button, Dropdown, Icon, Input, Menu, Select } from "antd";
import { TableProps } from "antd/lib/table/interface";
import { DataTableStore } from "@app/components/DataTable/DataTableStore";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { endpoints } from "@config/endpoints";
import { ajax } from "@lib/ajax";
import Spin from "antd/lib/spin";
import _ from "lodash";
import { sessionStore } from "@app/stores/SessionStore";

type Endpoint = keyof typeof endpoints;

interface IFilter {
    name: string;
    type:
        | "dropdown"
        | "checkbox"
        | "input"
        | "version-dropdown"
        | "app-v-dropdown"
        | "dropdown-search"
        | "dropdown-search-key-value"
        | "dropdown-multi";
    values?: Record<string, string>;
    defaultValue?: string | boolean | number;
    isLocal?: boolean;
    label?: string;
    placeholder?: string;
    endpoint?: Endpoint;
}

interface IDataTableProps<T extends IData> extends TableProps<T> {
    store: DataTableStore<T>;
    filter: IFilter;
}

@observer
export class DataTableFilter<T extends IData> extends React.Component<
    IDataTableProps<T>
> {
    @observable public selectedKey: string | boolean | number;
    @observable public fetching: boolean = false;
    @observable public values: any = {};

    constructor(props: IDataTableProps<T>) {
        super(props);
    }

    public async componentDidMount(): Promise<void> {
        const { store, filter } = this.props;
        if (filter.endpoint) {
            await this.loadFromApi(filter.endpoint);
        }

        if (filter.defaultValue) {
            if (filter.defaultValue !== "___") {
                store.dataProvider.addFilter(
                    [filter.name, filter.defaultValue],
                    filter.name
                );
            }
            this.selectedKey = filter.defaultValue;
        }
    }

    public async loadClients(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.client,
            params: {
                sort: ["id", "=", -1],
                limit: 50,
            },
        });
        this.values = resp.data.reduce(
            (a: any, v: any) => ({ ...a, [v.email]: v.email }),
            {}
        );
    }

    public async loadPackages(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                limit: 100,
                filters: [["type", "PACKAGE"]],
            },
        });
        this.values = resp.data.map((d: IPackage) => ({
            name: d.title,
            value: d.id,
        }));
    }

    public async loadModules(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.package,
            params: {
                limit: 100,
                filters: [["type", "MODULE"]],
            },
        });
        this.values = resp.data.map((d: IPackage) => ({
            name: d.title,
            value: d.id,
        }));
    }

    public async loadPartners(): Promise<any> {
        const resp = await ajax.get({
            url: endpoints.partners,
            params: {
                sort: ["id", "=", -1],
                limit: 50,
            },
        });
        const list = [sessionStore.user.partner, ...resp.data];
        this.values = list.map((d: IPartner) => ({
            name: d.name,
            value: d.id,
        }));
    }

    public onFetchClient = _.debounce(async (value: string): Promise<void> => {
        this.fetching = true;
        let params: any = {
            sort: ["id", "=", -1],
        };
        if (value) {
            params = {
                filters: [["email", "=", value]],
            };
        }
        const resp = await ajax.get({
            url: endpoints.client,
            params,
        });
        this.fetching = false;
        this.values = resp.data.reduce(
            (a: any, v: any) => ({ ...a, [v.email]: v.email }),
            {}
        );
    }, 500);

    public loadFromApi = async (api: Endpoint): Promise<any[]> => {
        switch (api) {
            case "client":
                return await this.loadClients();
            case "package":
                return await this.loadPackages();
            case "partners":
                return await this.loadPartners();
            case "module":
                return await this.loadModules();
            default:
                return [];
        }
    };

    public onClick = (value: any) => {
        const key = _.isArray(value)
            ? value
            : _.isObject(value)
            ? (value as any).key
            : value;
        const { store, filter } = this.props;
        this.selectedKey = key;
        if (filter.isLocal) {
            if (this.selectedKey === "___") {
                store.dataProvider.resetFilterByFn();
            } else {
                store.dataProvider.filterByFn(
                    (d) => (d as any)[filter.name]! === this.selectedKey
                );
            }
        } else {
            if (
                key === "___" ||
                _.isUndefined(value) ||
                (_.isArray(key) && key.length === 0)
            ) {
                store.dataProvider.removeFilter(this.props.filter.name);
            } else {
                store.dataProvider.addFilter(
                    [this.props.filter.name, key],
                    this.props.filter.name
                );
            }
            store.dataProvider.loadData();
        }
    };

    public loadData = _.debounce(
        () => {
            this.props.store.dataProvider.onPageChange(1, 100);
            this.props.store.dataProvider.loadData();
        },
        300,
        { trailing: true, leading: false }
    );

    public onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const { store, filter } = this.props;
        const searchedValue = e.currentTarget.value;
        if (filter.isLocal) {
            if (searchedValue) {
                store.dataProvider.filterByFn(
                    (d) => (d as any)[filter.name]!.indexOf(searchedValue) > -1
                );
            } else {
                store.dataProvider.resetFilterByFn();
            }
        } else {
            if (searchedValue) {
                store.dataProvider.addFilter(
                    [filter.name, searchedValue],
                    filter.name
                );
            } else {
                store.dataProvider.removeFilter(filter.name);
            }
            this.loadData();
        }
    };

    public onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            this.props.store.dataProvider.onPageChange(1, 100);
            this.props.store.dataProvider.loadData();
        }
    };

    public render(): ReactNode {
        switch (this.props.filter.type) {
            case "dropdown-multi":
                return (
                    <Select
                        placeholder={this.props.filter?.placeholder}
                        showSearch
                        allowClear
                        style={{ width: 200, marginRight: 5 }}
                        onChange={this.onClick}
                        mode="multiple"
                        optionFilterProp="children"
                        notFoundContent={
                            this.fetching ? <Spin size="small" /> : null
                        }
                        onSearch={this.onFetchClient}
                        filterOption={false}
                    >
                        {_.map(this.values, (data) => (
                            <Select.Option key={data.name} value={data.value}>
                                {data.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "dropdown-search-key-value":
                return (
                    <Select
                        placeholder={this.props.filter?.placeholder}
                        showSearch
                        allowClear
                        style={{ width: 200 }}
                        onChange={this.onClick}
                        optionFilterProp="children"
                        notFoundContent={
                            this.fetching ? <Spin size="small" /> : null
                        }
                        onSearch={this.onFetchClient}
                        filterOption={false}
                    >
                        {_.map(this.values, (data) => (
                            <Select.Option key={data.name} value={data.value}>
                                {data.name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "dropdown-search":
                return (
                    <Select
                        placeholder="Select email"
                        showSearch
                        allowClear
                        style={{ width: 200, marginRight: 5 }}
                        onChange={this.onClick}
                        optionFilterProp="children"
                        notFoundContent={
                            this.fetching ? <Spin size="small" /> : null
                        }
                        onSearch={this.onFetchClient}
                        filterOption={false}
                    >
                        {_.map(this.values, (val, key) => (
                            <Select.Option key={val} value={val}>
                                {val}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "dropdown":
                return (
                    <Dropdown
                        key={this.props.filter.name}
                        trigger={["click"]}
                        overlay={
                            <Menu
                                onClick={this.onClick}
                                selectedKeys={[`${this.selectedKey}`]}
                            >
                                <Menu.Item key="___">No Filter</Menu.Item>
                                {_.map(this.props.filter.values, (val, key) => (
                                    <Menu.Item key={key}>{val}</Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <Button style={{ marginBottom: 10 }}>
                            {this.props.filter.label || this.props.filter.name}
                            {this.selectedKey !== "___"
                                ? ": " +
                                  (this.props.filter.values as any)[
                                      this.selectedKey as any
                                  ]
                                : ""}
                            <Icon type="down" />
                        </Button>
                    </Dropdown>
                );
            case "input":
                return (
                    <div style={{ display: "inline-block" }}>
                        <Input placeholder="Search" onChange={this.onSearch} />
                    </div>
                );
            case "version-dropdown":
                return (
                    <Dropdown
                        key={this.props.filter.name}
                        trigger={["click"]}
                        overlay={
                            <Menu
                                onClick={this.onClick}
                                selectedKeys={[`${this.selectedKey}`]}
                            >
                                <Menu.Item key="___">No Filter</Menu.Item>
                                {_.map(
                                    _.groupBy(
                                        _.orderBy(
                                            _.orderBy(
                                                this.props.store.dataProvider
                                                    .list,
                                                ["v"],
                                                ["desc"]
                                            ),
                                            ["stage"],
                                            ["desc"]
                                        ),
                                        this.props.filter.name
                                    ),
                                    (val, key) => (
                                        <Menu.Item key={key}>
                                            {`(${_.capitalize(
                                                (val[0] as any).stage
                                            )})`}{" "}
                                            {key} (T: {val.length})
                                        </Menu.Item>
                                    )
                                )}
                            </Menu>
                        }
                    >
                        <Button style={{ marginBottom: 10 }}>
                            version
                            {this.selectedKey !== "___"
                                ? ": " + this.selectedKey
                                : ""}
                            <Icon type="down" />
                        </Button>
                    </Dropdown>
                );
            case "app-v-dropdown":
                return (
                    <Dropdown
                        key={this.props.filter.name}
                        trigger={["click"]}
                        overlay={
                            <Menu
                                onClick={this.onClick}
                                selectedKeys={[`${this.selectedKey}`]}
                            >
                                <Menu.Item key="___">No Filter</Menu.Item>
                                {_.map(
                                    _.groupBy(
                                        _.orderBy(
                                            _.orderBy(
                                                this.props.store.dataProvider.list.filter(
                                                    (t: any) => t.appV
                                                ),
                                                ["appV"],
                                                ["desc"]
                                            ),
                                            ["ua.os.name"],
                                            ["desc"]
                                        ),
                                        this.props.filter.name
                                    ),
                                    (val, key) => (
                                        <Menu.Item key={key}>
                                            {`(${_.capitalize(
                                                (val[0] as any).ua.os.name
                                            )})`}{" "}
                                            {key} (T: {val.length})
                                        </Menu.Item>
                                    )
                                )}
                            </Menu>
                        }
                    >
                        <Button style={{ marginBottom: 10 }}>
                            app-v
                            {this.selectedKey !== "___"
                                ? ": " + this.selectedKey
                                : ""}
                            <Icon type="down" />
                        </Button>
                    </Dropdown>
                );
            default:
                return null;
        }
    }
}

import { observable, action, computed } from "mobx";
import axios from "axios";
import { depot } from "@cloposcom/libs";
import { initSessionStore, sessionStore } from "@app/stores/SessionStore";
import { loadRoles } from "@app/data/roles";
import { createBrowserHistory } from "history";
import { loadPackages } from "@app/data/packages";

export class AppStore {

    @observable public isReady = false;

    @computed public get path(): string[] {
        return [location.pathname];
    }

    public history = createBrowserHistory();
}

export const initApp = action(async () => {
    depot.setPrefix("admin_");
    try {
        await initSessionStore();
        if (sessionStore.isLoggedIn) {
            await loadAppData();
        }
    } catch (e) {
        console.log(e);
    }
    appStore.isReady = true;
});

export const loadAppData = action(async (): Promise<void> => {
    if(sessionStore.role?.level !== 3) await loadRoles();
    await loadPackages();
});

export const appStore = new AppStore;

import { Header } from "@app/components/layout/header/header";
import { Sidebar } from "@app/components/layout/sidebar";
import { IS_MOBILE } from "@app/config/main";
import { routes } from "@app/config/routes";
import { appStore } from "@app/stores/AppStore";
import { Layout } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Route, Router } from "react-router-dom";

export const MainLayout = observer(() => {
    const [sidebarHidden, setSidebarHidden] = React.useState(IS_MOBILE ? 1 : !!localStorage.getItem("sidebarHidden") );

    return <Router history={appStore.history as any}>
        <Layout style={{ height: "100%" }}>
            <Sidebar hide={setSidebarHidden} isHidden={sidebarHidden} />
            <Layout style={{ marginLeft: sidebarHidden || IS_MOBILE ? 0 : 200 }}>
                <Header sidebarHidden={sidebarHidden} setSidebarHidden={setSidebarHidden} />
                <Layout.Content style={{ padding: 10 }}>

                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.main}
                        />
                    ))}
                </Layout.Content>
                {/* <Footer /> */}
            </Layout>

        </Layout>
    </Router>;
});

MainLayout.displayName = "MainLayout";
